declare const window: any;

const branchName = window.__env.branchName || 'develop';
console.log(branchName);

const environments: { [key: string]: any } = {
    sandbox: {
        environment: {
            production: false,
            api_url: "",
            payment_url: "",
            cloudinary: {
                api_key: '321842221971572',
                cloud_name: 'ddqvtwvdk',
                api_secret: 'ICDezxj5Oqm0cHRrZ8jeKT6IAUk',
                upload: {
                    url: 'https://api.cloudinary.com/v1_1/ddqvtwvdk/image/upload',
                },
                delete: {
                    url: 'https://api.cloudinary.com/v1_1/ddqvtwvdk/image/destroy',
                }
            },
            algolia: {
                appId: 'TPGQ58F06T',
                apiKey: '204b9d953eb1c42cc8f05ecb235a6f78'
            },
            emails_url: 'https://us-central1-quanto-software.cloudfunctions.net/emails-emails',
            mh: {
                environment: '00',
                url: 'https://apitest.dtes.mh.gob.sv'
            },
            api: 'https://sandbox.quantopos.com',
            solutions_url: 'https://testing-solutions.quantopos.com'
        },
        firebaseConfig: {
            apiKey: "AIzaSyBmreAMoptKaFVLWQb_oOl4agWhFR-KVA4",
            authDomain: "quanto-sandbox.firebaseapp.com",
            projectId: "quanto-sandbox",
            storageBucket: "quanto-sandbox.appspot.com",
            messagingSenderId: "451046662365",
            appId: "1:451046662365:web:9ca520c09b78cb719a3b34",
            measurementId: "G-SNN16Z8RDE"
        }
    },
    develop: {
        environment: {
            production: false,
            api_url: "",
            payment_url: "",
            cloudinary: {
                api_key: '321842221971572',
                cloud_name: 'ddqvtwvdk',
                api_secret: 'ICDezxj5Oqm0cHRrZ8jeKT6IAUk',
                upload: {
                    url: 'https://api.cloudinary.com/v1_1/ddqvtwvdk/image/upload',
                },
                delete: {
                    url: 'https://api.cloudinary.com/v1_1/ddqvtwvdk/image/destroy',
                }
            },
            algolia: {
                appId: 'TPGQ58F06T',
                apiKey: 'ab102c5d8f58e7bfffccb47f173d37d1'
            },
            emails_url: 'https://us-central1-quanto-software.cloudfunctions.net/emails-emails',
            mh: {
                environment: '00',
                url: 'https://apitest.dtes.mh.gob.sv'
            },
            api: 'https://apitest.quantopos.com',
            solutions_url: 'https://testing-solutions.quantopos.com'
        },
        firebaseConfig: {
            apiKey: "AIzaSyCXQ5pc8OrB4sm8KBJSFKs10ooPWyNirQ4",
            authDomain: "quanto-develop.firebaseapp.com",
            databaseURL: "https://quanto-develop-default-rtdb.firebaseio.com",
            projectId: "quanto-develop",
            storageBucket: "quanto-develop.appspot.com",
            messagingSenderId: "587942193426",
            appId: "1:587942193426:web:12fe044950532a7f75abcb"
        }

    },
    prod: {
        environment: {
            production: true,
            api_url: "",
            payment_url: "",
            cloudinary: {
                api_key: '321842221971572',
                cloud_name: 'ddqvtwvdk',
                api_secret: 'ICDezxj5Oqm0cHRrZ8jeKT6IAUk',
                upload: {
                    url: 'https://api.cloudinary.com/v1_1/ddqvtwvdk/image/upload',
                },
                delete: {
                    url: 'https://api.cloudinary.com/v1_1/ddqvtwvdk/image/destroy',
                }
            },
            algolia: {
                appId: 'QINPD3BNMP',
                apiKey: 'dcf0fa9d9a76502f7d697ef002fcd2fb'
            },
            emails_url: 'https://us-central1-quanto-software.cloudfunctions.net/emails-emails',
            mh: {
                environment: '01',
                url: 'https://api.dtes.mh.gob.sv'
            },
            api: 'https://api.quantopos.com',
            solutions_url: 'https://solutions.quantopos.com'
        },
        firebaseConfig: {
            apiKey: "AIzaSyBZPFTnJE76B_gAVNzcQqcVqy8006iendM",
            authDomain: "quanto-software.firebaseapp.com",
            projectId: "quanto-software",
            storageBucket: "quanto-software.appspot.com",
            messagingSenderId: "22922072346",
            appId: "1:22922072346:web:a5ecae40e3805b15361ca3",
            measurementId: "G-FWTGWXFC0J"
        }

    }
};

export const environment = environments[branchName].environment || environments['develop'].environment;
export const firebaseConfig = environments[branchName].firebaseConfig || environments['develop'].firebaseConfig;
