import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as currency from 'currency.js';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PosService } from 'src/app/services/pos/pos.service';
import { SuccessPaymentComponent } from '../success-payment/success-payment.component';

@Component({
    selector: 'app-payment-board',
    templateUrl: './payment-board.component.html',
    styleUrls: ['./payment-board.component.scss'],
})
export class PaymentBoardComponent implements OnInit {
    slideOptions = {
        slidesPerView: 'auto',
        zoom: false,
        autoplay: false,
        loop: false,
    };
    paying: any = false;
    order: any;
    amount: any = '';
    total: any;
    cash_options: any = [];
    wrong: any = false;
    deposit: any = 0;
    deposit_tip: any = 0;
    splitted_amount: any;
    branch: any;
    step: any = 0;
    authorization: any = '';
    params: any;
    items: any;
    currency_amount: any = 0;
    payment_gateways: any = [];
    gateway: any = false;
    giftcard: any = false;

    constructor(
        public modalController: ModalController,
        public api: ApiService,
        public navParams: NavParams,
        public components: ComponentsService,
        public auth: AuthService,
        public pos: PosService
    ) {
        this.params = this.navParams.data;
        this.order = this.navParams.data.order;
        this.total = this.navParams.data.pending;
        this.branch = this.navParams.data.branch;
        this.items = this.params.items;

    }

    ngOnInit() {
        if (this.params.payment_type == 'card') {
            this.api.getAllDocuments(`accounts/${this.auth.account}/payment_gateways`)
                .then((data) => {
                    if (data !== null) {
                        this.payment_gateways = data;
                    }
                });
        }
    }

    async makePayment() {
        const { discount_card, discounts } = this.order
        const discount = discounts?.find(({ isDiscountCard }) => isDiscountCard === true);
        if (!!discount_card && !!discount) {
            const response: any = await this.api.validateDiscountCard(discount_card.$key, discount.amount);
            if (response?.success === false) {
                this.components.dismissLoader();
                this.modalController.dismiss(response)
                return
            }
        }

        this.paying = true;
        let valid = true;
        let gateway = false;

        if (this.gateway) {
            gateway = this.gateway.name;
        }

        if (this.params.payment_type == 'card') {
            let pending = Math.round(this.params.pending * 100) / 100;

            if (this.currency_amount > pending) {
                valid = false;
                this.components.showToast('El monto no puede ser mayor al pendiente por pagar.', 'error');
            }
        }

        let deposit = 0;

        deposit = Number(this.deposit.toFixed(2));

        let tip = 0;
        tip = Number(this.deposit_tip.toFixed(2));

        if (this.params.payment_type == 'giftcard') {
            this.giftcard = this.params.giftcard;

            if (Number(this.giftcard.available_amount) < deposit) {
                valid = false;
                this.components.showToast('No tienes disponible el monto en la giftcard seleccionada.', 'error');
            }

            let pending = Math.round(this.params.pending * 100) / 100;

            if (this.currency_amount > pending) {
                valid = false;
                this.components.showToast('El monto no puede ser mayor al pendiente por pagar.', 'error');
            }
        }

        if (valid) {
            this.components.showLoader('Guardando cobro...').then(() => {
                let payment_data = {
                    gateway: gateway,
                    pending: this.params.pending,
                    order: this.order,
                    items: this.items,
                    discounts: this.params.discounts,
                    payments: this.params.payments,
                    tip: tip,
                    deposit: deposit,
                    subtotal: this.params.gran_subtotal,
                    hidden_taxes: this.components.fixed(this.params.gran_hidden_taxes),
                    exempt: this.params.gran_exempt,
                    gran_total: this.params.gran_total,
                    gran_tip: this.params.gran_tip,
                    gran_discount: this.params.gran_discount,
                    method: this.params.payment_type,
                    other_payment: this.params.other_payment,
                    other: this.params.other,
                    giftcard: this.giftcard,
                    received: this.currency_amount.value
                }
                if (this.params.discount_card) {
                    payment_data['discount_card'] = this.params.discount_card;
                }

                this.api.makePayment(payment_data).then((response) => {
                    this.components.dismissLoader();
                    if (response['completed']) {
                        this.pos.clearOrder(undefined);
                        this.modalController.dismiss({
                            completed: true,
                            ticket_key: response['ticket_key'],
                            data_order: response['data_order']
                        });
                    } else {
                        this.modalController.dismiss({
                            data_order: response['data_order']
                        });
                    }
                }, err => {
                    this.paying = false;
                    this.params.payments.pop()
                    this.components.dismissLoader();
                    this.components.showAlert('Sucedio un error en la creación de DTE', `code: ${err.code} ${err.error}`, ['Aceptar'])
                });

            });
        }
    }

    successPaymentModal(ticket_key) {
        this.modalController
            .create({
                component: SuccessPaymentComponent,
                cssClass: 'full-modal',
                animated: false,
                backdropDismiss: true,
                componentProps: {
                    ticket_key: ticket_key,
                },
            })
            .then((modal) => {
                modal.present();
            });
    }

    setNumber(number) {
        this.amount += number;
        this.currency_amount = currency(this.amount, {
            fromCents: true,
            precision: 2,
        });
        this.changeTip();
    }

    clearAmount() {
        this.amount = '';
        this.currency_amount = currency(this.amount, {
            fromCents: true,
            precision: 2,
        });
        this.changeTip();
    }

    setOption(value) {
        let amount = Number(this.amount);
        amount += Number(value);
        this.amount = amount.toString();

        this.currency_amount = currency(this.amount, {
            fromCents: true,
            precision: 2,
        });
        this.changeTip();
    }

    setTotal() {
        this.amount = this.params.pending.toString();
        this.currency_amount = currency(this.amount);
        this.changeTip();
    }

    removeNumber() {
        this.amount = this.amount.substring(0, this.amount.length - 1);
        this.currency_amount = currency(this.amount, {
            fromCents: true,
            precision: 2,
        });
        this.changeTip();
    }

    changeTip() {
        let amount = this.currency_amount;

        if (this.currency_amount > this.total) {
            amount = this.total;
        }

        if (this.branch.active_tip && this.order.type == 'tables') {

            if (this.order.tip_removed) {
                this.deposit = Number(amount);
                this.deposit_tip = 0;
            } else {

                if (this.params.gran_discount > 0 || this.params.gran_exempt > 0) {
                    this.deposit_tip = amount * (this.branch.tip / 100);
                    this.deposit = Number(amount) - Number(Number(this.deposit_tip).toFixed(2));

                } else {
                    this.deposit = amount / (this.branch.tip / 100 + 1);
                    this.deposit_tip = Number(amount) - Number(Number(this.deposit).toFixed(2));
                }

            }
        } else {
            this.deposit = Number(amount);
        }
    }

    activeGateway(event) {
        this.gateway = event.detail.value;
    }

    setGateway(event, gateway) {
        if (event.detail.checked) {
            this.gateway = gateway;
        } else {
            this.gateway = false;
        }
    }
}

